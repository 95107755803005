import React, { useState, useEffect } from 'react';
import './OnePager.css';
import { Link } from 'react-router-dom';
import { FaBolt, FaQrcode, FaHandshake, FaCar, FaCheckCircle, FaMobileAlt, FaMapMarkerAlt } from 'react-icons/fa';
import { db } from '../firebase'; // referencing your existing firebase.js file that exports db
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore'; // ADDED getDocs, query, where for email validation

// Countdown Calculation
const calculateCountdown = (launchDate) => {
  const now = new Date().getTime();
  const distance = new Date(launchDate).getTime() - now;

  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds };
};

const OnePager = () => {
  const [ridesPerDay, setRidesPerDay] = useState(10);
  const [averageFare, setAverageFare] = useState(15);
  const [blitzDailyEarnings, setBlitzDailyEarnings] = useState(0);
  const [uberDailyEarnings, setUberDailyEarnings] = useState(0);
  const [lyftDailyEarnings, setLyftDailyEarnings] = useState(0);
  const [blitzMonthlyEarnings, setBlitzMonthlyEarnings] = useState(0);
  const [uberMonthlyEarnings, setUberMonthlyEarnings] = useState(0);
  const [lyftMonthlyEarnings, setLyftMonthlyEarnings] = useState(0);

  const [countdown, setCountdown] = useState(calculateCountdown('2025-05-02'));

  // Waitlist States
  const [showWaitlistModal, setShowWaitlistModal] = useState(true);
  const [showWaitlistSelection, setShowWaitlistSelection] = useState(true);
  const [waitlistSubmitted, setWaitlistSubmitted] = useState(false);
  const [userType, setUserType] = useState('');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [city, setCity] = useState('');

  // NEW STATES FOR EMAIL-BASED VALIDATION
  const [showAlreadyJoinedForm, setShowAlreadyJoinedForm] = useState(false);
  const [alreadyJoinedEmail, setAlreadyJoinedEmail] = useState('');
  const [alreadyJoinedError, setAlreadyJoinedError] = useState('');
  const [alreadyJoinedSuccess, setAlreadyJoinedSuccess] = useState(false);

  useEffect(() => {
    const hamburger = document.querySelector('.hamburger');
    const navMenu = document.querySelector('.main-nav');

    const toggleMenu = () => {
      navMenu.classList.toggle('active');
    };

    hamburger.addEventListener('click', toggleMenu);

    const interval = setInterval(() => {
      setCountdown(calculateCountdown('2025-05-02'));
    }, 1000);

    return () => {
      hamburger.removeEventListener('click', toggleMenu);
      clearInterval(interval);
    };
  }, []);

  // Check localStorage to see if user already joined
  useEffect(() => {
    const alreadyJoined = localStorage.getItem('joinedWaitlist') === 'true';
    if (alreadyJoined) {
      setShowWaitlistModal(false);
    }
  }, []);

  const formatNumber = (number) => number.toLocaleString();

  const calculateEarnings = () => {
    const blitzCommission = 0;
    const uberCommission = 0.25;
    const lyftCommission = 0.20;

    const dailyEarnings = ridesPerDay * averageFare;

    const blitzDaily = dailyEarnings - (dailyEarnings * blitzCommission);
    const uberDaily = dailyEarnings - (dailyEarnings * uberCommission);
    const lyftDaily = dailyEarnings - (dailyEarnings * lyftCommission);

    setBlitzDailyEarnings(blitzDaily);
    setUberDailyEarnings(uberDaily);
    setLyftDailyEarnings(lyftDaily);

    setBlitzMonthlyEarnings(blitzDaily * 30);
    setUberMonthlyEarnings(uberDaily * 30);
    setLyftMonthlyEarnings(lyftDaily * 30);
  };

  const handleWaitlistSubmit = async (e) => {
    e.preventDefault();
    if (!userType || !name || !lastName || !email || !phoneNumber || !city) {
      alert("Please fill all fields.");
      return;
    }

    try {
      await addDoc(collection(db, "waitlist"), {
        userType,
        name,
        lastName,
        email,
        phoneNumber,
        city,
        timestamp: new Date().toISOString()
      });
      // Mark in localStorage that the user has joined
      localStorage.setItem('joinedWaitlist', 'true');
      setWaitlistSubmitted(true);
    } catch (error) {
      console.error("Error adding to waitlist: ", error);
      alert("Error submitting waitlist information. Please try again later.");
    }
  };

  const handleAlreadyJoinedSubmit = async (e) => {
    e.preventDefault();
    setAlreadyJoinedError('');
    setAlreadyJoinedSuccess(false);

    if (!alreadyJoinedEmail) {
      setAlreadyJoinedError('Please enter your email.');
      return;
    }

    try {
      const q = query(collection(db, "waitlist"), where("email", "==", alreadyJoinedEmail));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        localStorage.setItem('joinedWaitlist', 'true');
        setAlreadyJoinedSuccess(true);
      } else {
        setAlreadyJoinedError('Email not found. Please join the waitlist.');
      }
    } catch (error) {
      console.error("Error checking waitlist email: ", error);
      setAlreadyJoinedError('Error verifying email. Please try again later.');
    }
  };

  return (
    <div className="one-pager">
      {showWaitlistModal && (
        <div className="waitlist-overlay">
          <div className="waitlist-modal">
            {!waitlistSubmitted && !alreadyJoinedSuccess && (
              <>
                <img src="/blitz_logo.png" alt="Blitz Logo" className="waitlist-logo" />
                <h2 className="waitlist-headline">Be the First to Know</h2>
                <p className="waitlist-subtext">Join our movement to revolutionize ridesharing. Secure your spot on the waitlist now!</p>
                
                {/* Skip button added right here */}
                <p
                  className="skip-button"
                  onClick={() => setShowWaitlistModal(false)}
                >
                  Skip
                </p>
                {/* End of Skip button */}

              </>
            )}

            {alreadyJoinedSuccess && (
              <div className="thank-you-message">
                <FaCheckCircle className="check-icon" />
                <h3>Welcome Back!</h3>
                <p>You have already joined the waitlist.</p>
                <button className="btn close-btn" onClick={() => setShowWaitlistModal(false)}>Close</button>
              </div>
            )}

            {!waitlistSubmitted && !alreadyJoinedSuccess && (
              <>
                {showWaitlistSelection && !showAlreadyJoinedForm && (
                  <div className="waitlist-selection">
                    <h3>Join the Waitlist</h3>
                    <p>Pick your role to get started:</p>
                    <div className="selection-buttons">
                      <button
                        className="btn"
                        onClick={() => { setUserType('Rider'); setShowWaitlistSelection(false); }}
                      >
                        I'm a Rider
                      </button>
                      <button
                        className="btn"
                        onClick={() => { setUserType('Driver'); setShowWaitlistSelection(false); }}
                      >
                        I'm a Driver
                      </button>
                    </div>
                    <p className="already-joined-link" onClick={() => { setShowAlreadyJoinedForm(true); setShowWaitlistSelection(false); }}>Already Joined?</p>
                  </div>
                )}

                {showAlreadyJoinedForm && (
                  <form onSubmit={handleAlreadyJoinedSubmit} className="already-joined-form">
                    <h3>Already Joined?</h3>
                    <div className="form-group">
                      <label>Enter your email</label>
                      <input
                        type="email"
                        value={alreadyJoinedEmail}
                        onChange={(e) => setAlreadyJoinedEmail(e.target.value)}
                        placeholder="Enter your email"
                      />
                    </div>
                    {alreadyJoinedError && <p className="already-joined-error">{alreadyJoinedError}</p>}
                    <button type="submit" className="btn submit-btn">Verify</button>
                    <p className="return-to-selection" onClick={() => { setShowAlreadyJoinedForm(false); setShowWaitlistSelection(true); }}>Back</p>
                  </form>
                )}

                {!showWaitlistSelection && !showAlreadyJoinedForm && (
                  <form onSubmit={handleWaitlistSubmit} className="waitlist-form">
                    <h3>Waitlist Sign-Up ({userType})</h3>
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter your first name"
                      />
                    </div>
                    <div className="form-group">
                      <label>Last Name</label>
                      <input
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        placeholder="Enter your last name"
                      />
                    </div>
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                      />
                    </div>
                    <div className="form-group">
                      <label>Phone Number</label>
                      <input
                        type="text"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        placeholder="Enter your phone number"
                      />
                    </div>
                    <div className="form-group">
                      <label>City</label>
                      <input
                        type="text"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        placeholder="Enter your city"
                      />
                    </div>
                    <button type="submit" className="btn submit-btn">Join Waitlist</button>
                  </form>
                )}
              </>
            )}

            {waitlistSubmitted && !alreadyJoinedSuccess && (
              <div className="thank-you-message">
                <FaCheckCircle className="check-icon" />
                <h3>Thank You!</h3>
                <p>You have been added to the waitlist. We will keep you posted!</p>
                <button className="btn close-btn" onClick={() => setShowWaitlistModal(false)}>Close</button>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="countdown-banner">
        <p>
          We're launching soon!
          <span> {countdown.days}d {countdown.hours}h {countdown.minutes}m {countdown.seconds}s </span>
          until the big launch on May 2st, 2025.
        </p>
      </div>

      <header className="hero-section">
        <img src="/blitz_logo.png" alt="BLITZ Logo" className="logo" />
        <div className="hamburger">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <nav className="main-nav nav-menu">
          <a href="#how-it-works" className="nav-link">How It Works</a>
          <a href="#about" className="nav-link">About</a>
          <a href="#drive" className="nav-link">Drive</a>
          <Link to="/login" className="nav-link">Login</Link>
        </nav>
        <div className="content">
          <div className="text-content left-aligned">
            <h1>
              Two Ways to Ride with <span className="blitz-container">Blitz<small className="trademark-info">™ – US Patent Pending</small></span>
            </h1>
            <p>
              Use the Blitz app to request a ride or generate a QR code within the app to get a ride on the spot from any driver you see.
            </p>
            <div className="cta-buttons">
              <Link to="/rider-coming-soon" className="btn">Sign Up to Ride</Link>
              <Link to="/register?type=driver" className="btn">Sign Up to Drive</Link>
            </div>

            <div className="app-badges">
              <div className="badge-container">
                <img 
                  src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg" 
                  alt="Download on the App Store"
                  className="store-badge-img"
                />
                <div className="badge-label">Coming Soon</div>
              </div>
              <div className="badge-container">
                <img 
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  alt="Get it on Google Play"
                  className="store-badge-img"
                />
                <div className="badge-label">Coming Soon</div>
              </div>
            </div>
          </div>
          <img src="/retromain.png" alt="Retro Main" className="main-image" />
        </div>
      </header>

      <section id="how-it-works" className="how-it-works-section">
        <h2>How It Works</h2>
        <div className="steps">
          <div className="step">
            <FaMobileAlt className="icon" />
            <h3>Open the Blitz App</h3>
            <p>Launch the app to request a ride or generate a QR code for an instant ride.</p>
          </div>
          <div className="step">
            <FaQrcode className="icon" />
            <h3>Choose Your Option</h3>
            <p>
              Within the app, either request a ride or generate a QR code to catch a ride with any driver you see—even if they're not registered yet.
            </p>
          </div>
          <div className="step">
            <FaHandshake className="icon" />
            <h3>Connect with Any Driver</h3>
            <p>
              Show your in-app QR code to any driver. They can scan it and get onboarded instantly through our web-based portal, connecting you both for the ride.
            </p>
          </div>
          <div className="step">
            <FaCar className="icon" />
            <h3>Enjoy the Ride</h3>
            <p>Sit back and relax while the driver takes you to your destination safely and efficiently.</p>
          </div>
        </div>
        <div className="how-it-works-cta">
          <h3>Experience the future of ridesharing.</h3>
          <Link to="/rider-coming-soon" className="btn cta-btn">Get Started with Blitz</Link>
        </div>
      </section>

      <section id="about" className="about-section">
        <div className="about-content">
          <h2>About <span className="highlight">BLITZ</span></h2>
          <p>
            BLITZ is transforming the way people move. Our mission is to provide a seamless, efficient, and safe transportation experience. Whether you're a rider looking for a quick trip across town or a driver seeking to maximize your earnings, BLITZ connects you to what matters most.
          </p>
        </div>
      </section>

      <section id="earnings-comparison" className="earnings-comparison-section">
        <div className="comparison-content">
          <h2>Compare Your Earnings</h2>
          <p>See how much more you could make with <span className="highlight">Blitz</span>, commission-free, compared to Uber and Lyft.</p>
          <p className="disclaimer">Estimates are based on average commission rates and fares. Actual earnings may vary.</p>
          <div className="comparison-inputs">
            <div className="input-group">
              <label>Rides Per Day</label>
              <input
                type="number"
                value={ridesPerDay}
                onChange={(e) => setRidesPerDay(Number(e.target.value))}
              />
            </div>
            <div className="input-group">
              <label>Average Fare ($)</label>
              <input
                type="number"
                value={averageFare}
                onChange={(e) => setAverageFare(Number(e.target.value))}
              />
            </div>
            <button className="calculate-btn" onClick={calculateEarnings}>Calculate Earnings</button>
          </div>

          <div className="comparison-results">
            <div className="result-group highlight blitz-card">
              <h3>Blitz Earnings</h3>
              <div className="result-box">
                <p><span>Daily:</span> ${formatNumber(blitzDailyEarnings.toFixed(2))}</p>
                <p><span>Monthly:</span> ${formatNumber(blitzMonthlyEarnings.toFixed(2))}</p>
                <div className="progress-bar">
                  <div className="progress" style={{ width: `${(blitzDailyEarnings / 500) * 100}%` }}></div>
                </div>
              </div>
            </div>
            <div className="result-group">
              <h3>Uber Earnings</h3>
              <div className="result-box">
                <p><span>Daily:</span> ${formatNumber(uberDailyEarnings.toFixed(2))}</p>
                <p><span>Monthly:</span> ${formatNumber(uberMonthlyEarnings.toFixed(2))}</p>
                <div className="progress-bar">
                  <div className="progress" style={{ width: `${(uberDailyEarnings / 500) * 100}%` }}></div>
                </div>
              </div>
            </div>
            <div className="result-group">
              <h3>Lyft Earnings</h3>
              <div className="result-box">
                <p><span>Daily:</span> ${formatNumber(lyftDailyEarnings.toFixed(2))}</p>
                <p><span>Monthly:</span> ${formatNumber(lyftMonthlyEarnings.toFixed(2))}</p>
                <div className="progress-bar">
                  <div className="progress" style={{ width: `${(lyftDailyEarnings / 500) * 100}%` }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="drive" className="cta-driver-section">
        <div className="driver-content">
          <h2>Become a Driver</h2>
          <ul className="benefits-list">
            <li><span className="checkmark">✔</span> Keep all your commission for the first month</li>
            <li><span className="checkmark">✔</span> Extend the commission-free period by 5 days for each driver you refer up to 2 months - Restrictions Apply*</li>
            <li><span className="checkmark">✔</span> Set your own schedule and earn on your terms</li>
          </ul>
          <Link to="/register?type=driver" className="btn primary-btn">Sign Up to Drive</Link>
        </div>
      </section>

      <footer>
        <p>Contact us: coming soon - for now contact us via social media to get in touch</p>
        <div className="footer-links">
          <a href="#">Privacy Policy</a>
          <a href="/tos">Terms of Service</a>
          <a 
            href="https://www.instagram.com/goblitzofficial" 
            target="_blank" 
            rel="noopener noreferrer" 
            style={{ display: "inline-flex", alignItems: "center" }}
          >
            Follow us on Instagram
            <img 
              src="https://cdn-icons-png.flaticon.com/512/733/733558.png" 
              alt="Instagram"
              style={{ width: "20px", height: "20px", marginLeft: "10px" }}
            />
          </a>
        </div>
      </footer>
    </div>
  );
};

export default OnePager;
